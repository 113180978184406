<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:body>
            <b-row>
              <b-col lg="12" sm="12">
                <b-table-simple class="tg mt-3" hover small caption-top>
                  <tbody>
                    <tr>
                      <th style="width: 15%"  class="text-right" >{{ $t('elearning_iabm.circular_memo_no') }}</th>
                      <th class="text-center" style="width: 5%">:</th>
                      <td style="width: 30%" class="text-left">{{ formData.circular_memo_no }}</td>
                      <th style="width: 15%" class="text-right" >{{ $t('elearning_config.fiscal_year') }}</th>
                      <th class="text-center" style="width: 5%">:</th>
                      <td style="width: 30%" class="text-left">{{ $i18n.locale === 'bn' ? formData.fiscal_year_bn : formData.fiscal_year }}</td>
                    </tr>
                    <tr>
                      <th style="width: 15%"  class="text-right">{{ $t('elearning_config.organization') }}</th>
                      <th style="width: 5%" class="text-center">:</th>
                      <td style="width: 30%" class="text-left">{{ $i18n.locale === 'bn' ? formData.org_bn : formData.org }}</td>
                      <th style="width: 15%"  class="text-right">{{ $t('elearning_config.training_type') }}</th>
                      <th style="width: 5%" class="text-center">:</th>
                      <td style="width: 30%">{{ $i18n.locale === 'bn' ? formData.training_type_bn : formData.training_type }}</td>
                    </tr>
                    <tr>
                      <th style="width: 15%"  class="text-right">{{ $t('elearning_config.training_category') }}</th>
                      <th style="width: 5%" class="text-center">:</th>
                      <td style="width: 30%">{{ $i18n.locale === 'bn' ? formData.training_category_bn : formData.training_category }}</td>
                      <th style="width: 15%"  class="text-right">{{ $t('elearning_config.training_title') }}</th>
                      <th style="width: 5%" class="text-center">:</th>
                      <td style="width: 30%">{{ $i18n.locale === 'bn' ? formData.training_title_bn : formData.training_title }}</td>
                    </tr>
                    <tr>
                      <th style="width: 15%"  class="text-right">{{ $t('externalTraining.trainer_name') }}</th>
                      <th style="width: 5%" class="text-center">:</th>
                      <td style="width: 30%">
                        <!-- {{ getTrainerName(formData.trainer_id) }} -->
                        {{ $i18n.locale === 'bn' ? itemData.speaker_bn : itemData.speaker }}
                      </td>
                      <th style="width: 15%"  class="text-right" >{{ $t('elearning_tim.training_date') }}</th>
                      <th class="text-center" style="width: 5%">:</th>
                      <td style="width: 30%" class="text-left">{{ formData.training_date | dateFormat}}</td>
                    </tr>
                    <tr>
                      <th style="width: 15%"  class="text-right" >{{ $t('elearning_tpm.course_name') }}</th>
                      <th class="text-center" style="width: 5%">:</th>
                      <td style="width: 80%" class="text-left" colspan="4">{{ $i18n.locale === 'bn' ? formData.topics_session_bn : formData.topics_session }}</td>
                    </tr>
                  </tbody>
                </b-table-simple>
                <b-row>
                  <div class="col-md-12" id="form">
                    <fieldset class="p-2 w-100">
                      <legend class="px-2 w-50 shadow-sm">{{$t('externalTraining.evaluation_list')}}</legend>
                      <b-overlay :show="loading">
                        <table class="table" style="width:100%" v-if="allData.trainer_evaluation_marks.length > 0">
                          <thead class="thead">
                            <tr>
                              <th class="text-center">{{$t('globalTrans.sl_no')}}</th>
                              <th>{{$t('elearning_config.trainer_evaluation_name')}}</th>
                              <th style="width: 25%" class="text-left">{{$t('elearning_config.title')}}</th>
                            </tr>
                          </thead>
                          <tr v-for="(item,index) in allData.trainer_evaluation_marks" :key="index">
                            <td class="text-center">
                              {{$n(index+1)}}
                            </td>
                            <td>
                              <!-- <pre>{{$store.state.ExternalUserTraining.traineeTranerObj.trainerEvaluationList}}</pre> -->
                              {{ getColumnName($store.state.ExternalUserTraining.traineeTranerObj.trainerEvaluationList, item.trainer_evaluation_detail_id)}}
                              <!-- {{ $i18n.locale === 'bn' ? item.evaluation_name : item.evaluation_name }} -->
                            </td>
                            <td>
                              {{ $n(item.attain_marks) }}
                            </td>
                            <!-- <td v-for="(dateData, dateIndex) in dates" :key="dateIndex">
                              {{dateCheckEvaluation(item.trainer_evaluation_detail_id, dateData, item)}}
                            </td> -->
                          </tr>
                        </table>
                      </b-overlay>
                    </fieldset>
                  </div>
                </b-row>
              </b-col>
            </b-row>
          </template>
        </iq-card>
        <!-- <pre>{{allData}}</pre> -->
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import flatpickr from 'flatpickr'
import RestApi, { trainingElearningServiceBaseUrl, seedFertilizerServiceBaseUrl } from '@/config/api_config'
import { allTrainerData } from '../../api/routes'
import ExportPdf from './export_pdf_details'

export default {
  props: ['id', 'item'],
  created () {
    if (this.id) {
      const tmp = this.getFormData()
      this.formData = tmp
      this.itemData = this.item
      this.getOrgTrainerList()
      this.evaluationData()
    }
  },
  mounted () {
    core.index()
    flatpickr('.fromDate', {})
  },
  data () {
    return {
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      formData: {
        id: '',
        name: '',
        email: '',
        mobile: '',
        fiscal_year_id: 0,
        office_type_id: 0,
        office_id: 0,
        org_id: 0,
        room_type_id: 0,
        room_rent_id: 0,
        floor_id: 0,
        room_id: 0
      },
      itemData: {},
      loading: false,
      datas: [],
      allData: {
        fiscal_year_id: 0,
        trainer_evaluation_marks: []
      },
      dates: [],
      officeTypeList: [],
      officeList: [],
      roomlist: [],
      floorlist: [],
      roomRentList: [],
      trainerListData: [],
      trainingCategoryList: [],
      dateErrorMsg: '',
      trainingElearningServiceBaseUrl: trainingElearningServiceBaseUrl,
      seedFertilizerServiceBaseUrl: seedFertilizerServiceBaseUrl,
      trainingTitleList: []
    }
  },
  computed: {
    trainerList: function () {
      const listObject = this.trainerListData
      return listObject.map((obj, index) => {
        if (this.$i18n.locale === 'bn') {
          return { value: obj.value, text: obj.text_bn, text_en: obj.text_en, text_bn: obj.text_bn }
        } else {
          return { value: obj.value, text: obj.text_en, text_en: obj.text_en, text_bn: obj.text_bn }
        }
      })
    }
  },
  methods: {
    async getOrgTrainerList () {
      this.trainerLoading = true
      const params = {
        org_id: 0,
        profession_type: 0
      }
      const result = await RestApi.getData(trainingElearningServiceBaseUrl, allTrainerData, params)
      if (!result.success) {
        this.trainerListData = []
      } else {
        this.trainerListData = result.data
      }
      this.trainerLoading = false
    },
    getTrainerName (id) {
    const trainerName = this.trainerList.find(doc => doc.value === id)
      if (this.$i18n.locale === 'bn') {
        return trainerName !== undefined ? trainerName.text_bn : ''
      } else {
        return trainerName !== undefined ? trainerName.text_en : ''
      }
    },
    getColumnName (list, groupId) {
      const obj = list.find(item => item.value === groupId)
      if (typeof obj !== 'undefined') {
        if (this.$i18n.locale === 'bn') {
          return obj.text_bn
        }
        return obj.text_en
      } else {
          return ''
      }
    },
    dateCheckEvaluation (trainerEvaluationDetailId, dateData, item) {
      const evaluationMark = this.datas.find(data => data.training_date === dateData)
      if (typeof evaluationMark !== 'undefined') {
        const mark = evaluationMark.trainer_evaluation_marks.find(evaluationMark => evaluationMark.trainer_evaluation_detail_id === trainerEvaluationDetailId)
        const grdObj = this.$store.state.ExternalUserTraining.traineeTranerObj.gradingStatus.find(gradingStatus => gradingStatus.value === parseInt(mark.grading_status_id))
        if (typeof grdObj !== 'undefined') {
          return grdObj.text
        }
      }
      return item
      // courseData.obtained_mark = typeof mark !== 'undefined' ? mark.obtained_mark : 0
    },
    getFormData () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    },
    async evaluationData () {
      this.loading = true
      const params = {
        circular_memo_no: this.formData.circular_memo_no,
        course_id: this.formData.course_id,
        trainer_id: this.formData.trainer_id,
        trainee_id: this.item.trainee_id
      }
      const result = await RestApi.getData(trainingElearningServiceBaseUrl, 'external-user/trainer-evaluation/details', params)
      if (result.success) {
        this.allData = result.data
      }
      this.loading = false
    },
    pdfExport () {
      const reportTitle = this.$t('externalTraining.trainer_evaluation') + this.$t('globalTrans.details')
      ExportPdf.exportPdfDetails(seedFertilizerServiceBaseUrl, '/fertilizer/config/report-heading/detail', 3, reportTitle, this.formData, this, this.datas, this.dates)
    }
  }
}
</script>
