import i18n from '@/i18n'
import Store from '@/store'
import ReportHeading from '@/Utils/report-head'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFontsBn from 'pdfmake/build/vfs_fonts_bn'
import pdfFontsEn from 'pdfmake/build/vfs_fonts_en'
import { dateFormat } from '@/Utils/fliter'
function dateCheckEvaluation (trainerEvaluationDetailId, dateData, item, datas, gradingStatus) {
    const evaluationMark = datas.find(data => data.training_date === dateData)
    if (typeof evaluationMark !== 'undefined') {
        const mark = evaluationMark.trainer_evaluation_marks.find(evaluationMark => evaluationMark.trainer_evaluation_detail_id === trainerEvaluationDetailId)
        const grdObj = gradingStatus.find(gradingStatus => gradingStatus.value === parseInt(mark.grading_status_id))
        if (typeof grdObj !== 'undefined') {
            return grdObj.text
        }
    }
    return item
}
const exportPdfDetails = async (baseUrl, uri = '/report-heading/detail', orgId, reportTitle, data, vm, datas, dates) => {
    try {
      Store.commit('mutateCommonProperties', {
        loading: true
      })
      if (i18n.locale === 'bn') {
        pdfMake.vfs = pdfFontsBn.pdfMake.vfs
      } else {
          pdfMake.vfs = pdfFontsEn.pdfMake.vfs
      }
        const reportHeadData = await ReportHeading.getReportHead(baseUrl, uri, orgId)
        // const waterMarkText = i18n.locale === 'bn' ? 'কৃষি মন্ত্রণালয়' : 'Ministry of Agriculture'
        const pdfContent = [
          {
              columns: reportHeadData.reportHeadColumn
          },
          {
            text: vm.$t('fertilizerReport.krishi_bhaban'),
            style: 'krishi',
            alignment: 'center'
          },
          { text: reportHeadData.address, style: 'header3', alignment: 'center' }
        ]

        if (reportHeadData.projectName) {
          pdfContent.push({ text: reportHeadData.projectName, style: 'header3', alignment: 'center' })
        }

        pdfContent.push({ text: reportTitle, style: 'header2', alignment: 'center', decoration: 'underline' })
        pdfContent.push(
          {
            table: {
              style: 'tableData',
              headerRows: 0,
              widths: ['20%', '5%', '25%', '20%', '5%', '25%'],
              body: [
                [
                  { text: vm.$t('elearning_iabm.circular_memo_no'), style: 'th', alignment: 'right' },
                  { text: ':', style: 'td', alignment: 'center' },
                  { text: data.circular_memo_no, style: 'td', alignment: 'left' },
                  { text: vm.$t('elearning_config.fiscal_year'), style: 'th', alignment: 'right' },
                  { text: ':', style: 'td', alignment: 'center' },
                  { text: vm.$i18n.locale === 'bn' ? data.fiscal_year_bn : data.fiscal_year, style: 'td', alignment: 'left' }
                ],
                [
                  { text: vm.$t('globalTrans.organization'), style: 'th', alignment: 'right' },
                  { text: ':', style: 'td', alignment: 'center' },
                  { text: vm.$i18n.locale === 'bn' ? data.org_bn : data.org, style: 'td', alignment: 'left' },
                  { text: vm.$t('elearning_config.office_type'), style: 'th', alignment: 'right' },
                  { text: ':', style: 'td', alignment: 'center' },
                  { text: vm.$i18n.locale === 'bn' ? data.office_type_bn : data.office_type, style: 'td', alignment: 'left' }
                ],
                [
                  { text: vm.$t('globalTrans.office'), style: 'th', alignment: 'right' },
                  { text: ':', style: 'td', alignment: 'center' },
                  { text: vm.$i18n.locale === 'bn' ? data.office_bn : data.office, style: 'td', alignment: 'left' },
                  { text: vm.$t('elearning_config.training_type'), style: 'th', alignment: 'right' },
                  { text: ':', style: 'td', alignment: 'center' },
                  { text: vm.$i18n.locale === 'bn' ? data.training_type_bn : data.training_type, style: 'td', alignment: 'left' }
                ],
                [
                  { text: vm.$t('elearning_config.training_category'), style: 'th', alignment: 'right' },
                  { text: ':', style: 'td', alignment: 'center' },
                  { text: vm.$i18n.locale === 'bn' ? data.training_category_bn : data.training_category, style: 'td', alignment: 'left' },
                  { text: vm.$t('elearning_config.training_title'), style: 'th', alignment: 'right' },
                  { text: ':', style: 'td', alignment: 'center' },
                  { text: vm.$i18n.locale === 'bn' ? data.training_title_bn : data.training_title, style: 'td', alignment: 'left' }
                ],
                [
                  { text: vm.$t('externalTraining.trainer_name'), style: 'th', alignment: 'right' },
                  { text: ':', style: 'td', alignment: 'center' },
                  { text: vm.$i18n.locale === 'bn' ? data.trainer_name_bn : data.trainer_name, style: 'td', alignment: 'left' },
                  {},
                  {},
                  {}
                ]
              ]
            },
            layout: {
              hLineWidth: function (i, node) {
                return 0
              },
              vLineWidth: function (i, node) {
                return 0
              }
            }
          }
        )
        pdfContent.push({ text: vm.$t('externalTraining.evaluation_list'), style: 'hh', alignment: 'center' })
        const gradingStatus = vm.$store.state.ExternalUserTraining.traineeTranerObj.gradingStatus
        const allRows = []
        const thirdHeader = [
          { text: vm.$t('globalTrans.sl_no'), style: 'th', alignment: 'center', bold: true },
          { text: vm.$t('elearning_config.trainer_evaluation_name'), style: 'th', alignment: 'center', bold: true }
        ]
        dates.forEach(dateItem => {
          thirdHeader.push(
            { text: dateFormat(dateItem), style: 'th1', alignment: 'center', bold: true }
          )
        })
        allRows.push(thirdHeader)
        data.trainer_evaluation_marks.filter((item, index) => {
            const rowItem = [
                { text: vm.$n(index + 1), style: 'td', alignment: 'center' },
                { text: vm.$i18n.locale === 'bn' ? item.evaluation_name_bn : item.evaluation_name, style: 'td', alignment: 'center' }
            //   { text: (vm.$i18n.locale === 'en') ? vm.$store.state.ExternalUserTraining.traineeTranerObj.gradingStatus.find(gradingStatus => gradingStatus.value === parseInt(item.grading_status_id)).text_en : vm.$store.state.ExternalUserTraining.traineeTranerObj.gradingStatus.find(grading => grading.value === parseInt(item.grading_status_id)).text_en, style: 'td', alignment: 'center' }
            ]
            dates.forEach(dateItem => {
                const dataCheck = dateCheckEvaluation(item.trainer_evaluation_detail_id, dateItem, item, datas, gradingStatus)
                rowItem.push(
                    { text: dataCheck, style: 'td', alignment: 'center' }
                )
                // allRows.push(rowItem)
            })
          allRows.push(rowItem)
        })
        // option
        // let allRows = []
        // allRows = [
        //   [
        //     { text: vm.$t('globalTrans.sl_no'), style: 'th', alignment: 'center' },
        //     { text: vm.$t('elearning_config.trainer_evaluation_name'), style: 'th', alignment: 'center' },
        //     { text: vm.$t('elearning_config.title'), style: 'th', alignment: 'center' }
        //   ]
        // ]
        //   // table body
        // data.trainer_evaluation_marks.filter((item, index) => {
        //   const rowItem = [
        //       { text: vm.$n(index + 1), style: 'td', alignment: 'center' },
        //       { text: vm.$i18n.locale === 'bn' ? item.evaluation_name_bn : item.evaluation_name, style: 'td', alignment: 'center' },
        //       { text: (vm.$i18n.locale === 'en') ? vm.$store.state.ExternalUserTraining.traineeTranerObj.gradingStatus.find(gradingStatus => gradingStatus.value === parseInt(item.grading_status_id)).text_en : vm.$store.state.ExternalUserTraining.traineeTranerObj.gradingStatus.find(grading => grading.value === parseInt(item.grading_status_id)).text_en, style: 'td', alignment: 'center' }
        //   ]
        //   allRows.push(rowItem)
        // })
        pdfContent.push({ text: '', style: 'tableMargin' })
        pdfContent.push({
          table: {
            headerRows: 1,
            widths: '*',
            body: allRows
          }
        })
        var docDefinition = {
        content: pdfContent,
        pageSize: 'A4',
        pageOrientation: 'landscape',
        // watermark: { text: waterMarkText, color: 'blue', opacity: 0.1, bold: true, italics: false },
        styles: {
          th: {
            fontSize: (i18n === 'bn') ? 12 : 10,
            margin: [3, 3, 3, 3]
          },
          td: {
            fontSize: (i18n === 'bn') ? 12 : 10,
            margin: [3, 3, 3, 3]
          },
          header: {
            fontSize: 12,
            margin: [0, 0, 0, 4]
          },
          header2: {
            fontSize: 14,
            margin: [0, 10, 0, 20]
          },
          fertilizer: {
            fontSize: 10,
            margin: [10, 10, 0, 20]
            },
          hh: {
              fontSize: 13,
              bold: true,
              margin: [10, 10, 25, 20]
          },
          headerPort1: {
            fontSize: 10,
            margin: [0, 20, 0, 0]
          },
          headerPort: {
            fontSize: 10,
            margin: [0, 4, 0, 15]
          },
          krishi: {
            margin: [0, -5, 0, 15],
            alignment: 'center'
          },
          header3: {
            fontSize: 9,
            margin: [0, 0, 0, 4]
          },
          tableSubHead: {
            margin: [0, 5, 0, 15]
          }
        }
      }
      pdfMake.createPdf(docDefinition, null, null, null).download('trainer-evaluation')
    } catch (error) {
      if (error) {}
    }
    Store.commit('mutateCommonProperties', {
      loading: false
    })
}
export default {
  exportPdfDetails
}
